import React from 'react';
import { useHistory } from 'react-router';
import { IonButton, IonIcon } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';

interface HBBackProps {
  route?: string
}

const HBBack: React.FC<HBBackProps> = ({ route }) => {

  const history = useHistory();

  return <div className='w-full color-white'>
    <IonButton onClick={(e) => {
      e.stopPropagation();

      if (route) {
        history.push(route);
      } else {
        history.goBack()
      }
    }} fill="clear">
      <IonIcon icon={arrowBack} color='light' />
    </IonButton>
  </div>
};

export default HBBack;
