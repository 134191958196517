import React, { useState } from 'react';
import { IonRow, IonCol, IonIcon, IonProgressBar, IonSkeletonText } from '@ionic/react';
import { settingsOutline, ticketOutline } from 'ionicons/icons';
import './ProfileHeader.scss';
import Coin from '../Coin';
import { chevronForwardOutline } from 'ionicons/icons';
import ProductsModal from '../ProductsModal';
import { useAppSelector } from '../../hooks';
import { useHistory } from 'react-router';
import { formatNumber, getUserLevelAndNext } from '../../utils';
import { selectIdleCoinsPerHour, selectLotteryTickets, selectTotalCoins } from '../../redux/userSlice';
import { getProductsStatus, getProfitPerHour } from '../../redux/productSlice';
import HBIcon from '../HBIcon';
import { IconNameEnum } from '../HBIcon/HBIcon';
import { useInitData } from '@telegram-apps/sdk-react';
import { levels } from '../../constants';
import ProfitPerHourModal from '../ProfitPerHourModal';

const ProfileHeader: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isProfitPerHourModalOpen, setIsProfitPerHourModalOpen] = useState(false);
  const totalCoins = useAppSelector(selectTotalCoins);
  const profitPerHour = useAppSelector(getProfitPerHour);
  const idleCoinsPerHour = useAppSelector(selectIdleCoinsPerHour);
  const productsStatus = useAppSelector(getProductsStatus);
  const lotteryTickets = useAppSelector(selectLotteryTickets);
  const history = useHistory();

  const { currentLevel, percentageToNextLevel, levelIndex } = getUserLevelAndNext(totalCoins);

  const initData = useInitData();
  let firstName = initData?.user?.firstName || ''

  return (
    <div className="profile-header">
      <IonRow className="profile-top">
        <IonCol className="profile-info" size="9">
          <div><HBIcon size={32} name={IconNameEnum.Apiarist} className='mr-2' /></div>
          <div className="profile-name">
            <span>{firstName}</span>
          </div>
        </IonCol>
        <IonCol size="2" className="settings-col">
          <IonIcon icon={ticketOutline} className="settings-icon mr-1" />
          <span className='text-xl'>{lotteryTickets}</span>
        </IonCol>
        <IonCol size="1" className="settings-col">
          <IonIcon onClick={() => history.push('/settings')} icon={settingsOutline} className="settings-icon" />
        </IonCol>
      </IonRow>
      <IonRow className="profile-bottom">
        <IonCol size="5" className="level-info">
          <div className="level-details" onClick={(e) => {
            e.stopPropagation();
            history.push('/levels');
          }}>
            <span className="level-text">{currentLevel.name.split(' ')[0]}
              <IonIcon icon={chevronForwardOutline}></IonIcon>
            </span>
            <span className="level-progress">{levelIndex}/{levels.length - 1}</span>
          </div>
          <IonProgressBar value={percentageToNextLevel} color="primary" />
        </IonCol>
        <IonCol size="7" className="profit-info">
          <div className="profit-details">
            <div onClick={() => setIsOpen(true)} >
              <div className="profit-text text-center">Prod. per hour</div>
              <div className="profit-amount">
                <HBIcon name={IconNameEnum.TimeIsMoney} size={18} className='mr-2' />

                {productsStatus === 'succeeded' ?
                  <span >+{formatNumber(profitPerHour, 0)}</span> :
                  <IonSkeletonText animated={true} className='w-8' />
                }
              </div>
            </div>
            <div className='w-px h-10 mx-4 bg-white'></div>
            <div onClick={() => setIsProfitPerHourModalOpen(true)}>
              <div className="profit-text text-center">Prof. per hour</div>
              <div className="profit-amount">
                <Coin size={16} />
                <span >+{formatNumber(idleCoinsPerHour, 0)}</span>
              </div>
            </div>
          </div>
        </IonCol>
      </IonRow>

      <ProductsModal isOpen={isOpen} onDidDismiss={() => setIsOpen(false)} />
      <ProfitPerHourModal isOpen={isProfitPerHourModalOpen} onDidDismiss={() => setIsProfitPerHourModalOpen(false)} />
    </div>
  );
};

export default ProfileHeader;
