
import React, { useEffect, useState } from 'react';
import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonPage, IonSkeletonText, IonThumbnail } from '@ionic/react';
import Coin from '../components/Coin';
import HBIcon from '../components/HBIcon';
import { IconNameEnum } from '../components/HBIcon/HBIcon';
import './EarnTab.scss';
import { chevronForward } from 'ionicons/icons';
import DailyTaskModal from '../components/DailyTaskModal';
import { formatNumber } from '../utils';
import { useAppDispatch, useAppSelector } from '../hooks';
import { fetchUserState, selectDailyLoginTasks, selectDailyTasksStatus, selectTotalDailyLoginTasksEarnings } from '../redux/dailyTasksSlice';
import { DateTime } from 'luxon';
import { VideoTask, fetchVideoTasksState, selectAllVideoTasks, selectVideoTasksStatus } from '../redux/videoTasksSlice';
import VideoTaskModal from '../components/VideoTaskModal';
import { SocialMediaTask, SocialMediaTaskType, fetchSocialMediaTasks, selectAllSocialMediaTasks, selectSocialMediaTasksStatus } from '../redux/socialMediaTasksSlice';
import SocialMediaTasksModal from '../components/SocialMediaTasksModal/SocialMediaTasksModal';
import { Task, TaskType, fetchTasks, selectAllTasks, selectTasksStatus } from '../redux/tasksSlice';
import TaskModal from '../components/TaskModal';



const socialMediaTaskIcons = {
  [SocialMediaTaskType.TELEGRAM]: IconNameEnum.Telegram,
  [SocialMediaTaskType.TWITTER]: IconNameEnum.Twitter,
  [SocialMediaTaskType.TELEGRAM_PARTNERSHIP]: IconNameEnum.QuestionMark,
  [SocialMediaTaskType.TWITTER_POST]: IconNameEnum.Twitter,
}

const tasksIcons = {
  [TaskType.INVITE_3_FRIENDS]: IconNameEnum.Friends,
}

const OpenModalIcon: React.FC<{ isTaskCompleted: boolean }> = ({ isTaskCompleted }) => {
  return <div className='absolute right-2'>
    {isTaskCompleted && <HBIcon name={IconNameEnum.Check} size={24} />}
    {!isTaskCompleted && <IonIcon icon={chevronForward} />}
  </div>
}

const EarnTab: React.FC = () => {

  const dispatch = useAppDispatch();

  const [isDailyTaskModalOpen, setIsDailyTaskModalOpen] = useState(false);
  const [isSocialMediaTaskModalOpen, setIsSocialMediaTaskModalOpen] = useState(false);
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);

  const [selectedSocialMediaTask, setSelectedSocialMediaTask] = useState<SocialMediaTask>();
  const [selectedTask, setSelectedTask] = useState<Task>();

  const dailyTasksStatus = useAppSelector(selectDailyTasksStatus);
  const totalDailyLoginTasksEarnings = useAppSelector(selectTotalDailyLoginTasksEarnings);

  const today = DateTime.now().toISODate();
  const dailyLoginTasks = useAppSelector(selectDailyLoginTasks);
  const lastCompletedTask = dailyLoginTasks.find((task: any) => task.claimingDate === today);
  const hasActiveDailyLoginTask = lastCompletedTask ? !lastCompletedTask.isCompleted : true;

  const videoTasks = useAppSelector(selectAllVideoTasks);
  const videoTasksStatus = useAppSelector(selectVideoTasksStatus);
  const [selectedVideoTask, setSelectedVideTask] = useState<VideoTask | null>(null);
  const [isVideoTaskModalOpen, setIsVideoTaskModalOpen] = useState(false);

  const socialMediaTasksStatus = useAppSelector(selectSocialMediaTasksStatus);
  const socialMediaTasks = useAppSelector(selectAllSocialMediaTasks);

  const tasksStatus = useAppSelector(selectTasksStatus);
  const tasks = useAppSelector(selectAllTasks);

  const [videoModalKey, setVideoModalKey] = useState(`video_${Date.now()}`);
  const [socialMediaModalKey, setSocialMediaModalKey] = useState(`social_media_${Date.now()}`);
  const [taskModalKey, setTaskModalKey] = useState(`task_${Date.now()}`);

  const getSocialMediaIcon = (task: SocialMediaTask): IconNameEnum => {
    if (task.icon) {
      const icon = task.icon as IconNameEnum;
      const isValidEnumValue = Object.values(IconNameEnum).includes(icon);
      return isValidEnumValue ? IconNameEnum[icon] : socialMediaTaskIcons[task.type]
    }
    return socialMediaTaskIcons[task.type]
  }

  const sortedSocialMediaTasks = [...socialMediaTasks].sort((a, b) => {
    if (a.type === SocialMediaTaskType.TELEGRAM_PARTNERSHIP) return -1;
    if (b.type === SocialMediaTaskType.TELEGRAM_PARTNERSHIP) return 1;
    return 0;
  });


  useEffect(() => {
    if (dailyTasksStatus === 'idle') {
      dispatch(fetchUserState())
    }

    if (videoTasksStatus === 'idle') {
      dispatch(fetchVideoTasksState())
    }

    if (socialMediaTasksStatus === 'idle') {
      dispatch(fetchSocialMediaTasks());
    }

    if (tasksStatus === 'idle') {
      dispatch(fetchTasks());
    }
  })

  return (
    <IonPage className='earn-tab'>
      <IonContent fullscreen>
        <div className='flex flex-col items-center mt-16'>
          <div className='mb-8 coin-icon animate__animated animate__slideInDown'><Coin size={92} className='coin-icon' /></div>

          <div className='font-bold text-3xl mb-8 animate__animated animate__headShake'>Earn more coins</div>

          <div className='w-full p-4'>
            <div className='text-base'>HiveBits Youtube</div>
            <IonList className='list mb-8'>
              {videoTasks.map((task, index) => (
                <IonItem key={index} className='rounded-md mb-4' onClick={() => {
                  setSelectedVideTask(task);
                  setVideoModalKey(Date.now().toString());
                  setIsVideoTaskModalOpen(true);
                }}>
                  <HBIcon name={IconNameEnum.Youtube} size={48} className='mr-4' />

                  <div className='flex flex-col'>
                    <div className='text-base mb-1 w-64 truncate'>{task.title}</div>
                    <div className='flex items-center text-lg font-medium'><Coin size={20} className='mr-2' /> +{formatNumber(task?.reward || 0)} </div>
                  </div>
                  <OpenModalIcon isTaskCompleted={task.isCompleted} />
                </IonItem>
              ))}

              {videoTasksStatus !== 'succeeded' && [0, 1].map(item => (
                <IonItem key={item} className='rounded-md mb-4'>
                  <IonThumbnail slot="start" >
                    <IonSkeletonText animated={true} className='rounded-md'></IonSkeletonText>
                  </IonThumbnail>
                  <div className='flex flex-col'>
                    <div className='text-base mb-1'><IonSkeletonText animated={true} className='w-32' /></div>
                    <div className='flex items-center text-lg font-medium'><IonSkeletonText animated={true} className='w-8' /></div>
                  </div>
                </IonItem>
              ))}
            </IonList>

            <div className='text-base'>Daily tasks</div>
            <IonList className='list mb-8'>
              <IonItem className='rounded-md' onClick={() => setIsDailyTaskModalOpen(true)}>
                <HBIcon name={IconNameEnum.CalendarDay} size={48} className='mr-4' />
                <div className='flex flex-col items-start'>
                  <div className='text-base mb-1'>Daily reward</div>
                  <div className='flex items-center text-lg font-medium'><Coin size={20} className='mr-2' /> +{formatNumber(totalDailyLoginTasksEarnings)} </div>
                </div>

                <OpenModalIcon isTaskCompleted={!hasActiveDailyLoginTask} />
              </IonItem>
            </IonList>


            <IonLabel className='text-base'>Tasks list</IonLabel>
            <IonList className='list'>
              {sortedSocialMediaTasks.map((task, index) => (
                <IonItem key={index} className='rounded-md mb-4' hidden={task.type === SocialMediaTaskType.TELEGRAM_PARTNERSHIP && task.isCompleted} onClick={() => {
                  setSelectedSocialMediaTask(task);
                  setSocialMediaModalKey(Date.now().toString());
                  setIsSocialMediaTaskModalOpen(true);
                }}>
                  <HBIcon name={getSocialMediaIcon(task)} size={48} className='mr-4' />
                  <div className='flex flex-col'>
                    <div className='text-base mb-1 w-64 truncate'>{task.title}</div>
                    <div className='flex items-center text-lg font-medium'><Coin size={20} className='mr-2' /> +{formatNumber(task.reward)} </div>
                  </div>
                  <OpenModalIcon isTaskCompleted={task.isCompleted} />
                </IonItem>
              ))}

              {socialMediaTasksStatus !== 'succeeded' && [0, 1].map(item => (
                <IonItem key={item} className='rounded-md mb-4'>
                  <IonThumbnail slot="start" >
                    <IonSkeletonText animated={true} className='rounded-md'></IonSkeletonText>
                  </IonThumbnail>
                  <div className='flex flex-col'>
                    <div className='text-base mb-1'><IonSkeletonText animated={true} className='w-32' /></div>
                    <div className='flex items-center text-lg font-medium'><IonSkeletonText animated={true} className='w-8' /></div>
                  </div>
                </IonItem>
              ))}

              {tasks.map((task, index) => (
                <IonItem key={index} className='rounded-md mb-4' onClick={() => {
                  setSelectedTask(task);
                  setTaskModalKey(Date.now().toString());
                  setIsTaskModalOpen(true);
                }}>
                  <HBIcon name={tasksIcons[task.type]} size={48} className='mr-4' />
                  <div className='flex flex-col'>
                    <div className='text-base mb-1 w-64 truncate'>{task.title}</div>
                    <div className='flex items-center text-lg font-medium'><Coin size={20} className='mr-2' /> +{formatNumber(task.reward)} </div>
                  </div>
                  <OpenModalIcon isTaskCompleted={task.isCompleted} />
                </IonItem>
              ))}



              {tasksStatus !== 'succeeded' && [0].map(item => (
                <IonItem key={item} className='rounded-md mb-4'>
                  <IonThumbnail slot="start" >
                    <IonSkeletonText animated={true} className='rounded-md'></IonSkeletonText>
                  </IonThumbnail>
                  <div className='flex flex-col'>
                    <div className='text-base mb-1'><IonSkeletonText animated={true} className='w-32' /></div>
                    <div className='flex items-center text-lg font-medium'><IonSkeletonText animated={true} className='w-8' /></div>
                  </div>
                </IonItem>
              ))}
            </IonList>
          </div>
        </div>

        <div key={videoModalKey}>
          <VideoTaskModal isOpen={isVideoTaskModalOpen} onDidDismiss={() => setIsVideoTaskModalOpen(false)} task={selectedVideoTask} />
        </div>
        <DailyTaskModal hasActiveTask={hasActiveDailyLoginTask} dailyLoginTasks={dailyLoginTasks} isOpen={isDailyTaskModalOpen} today={today} onDidDismiss={() => setIsDailyTaskModalOpen(false)} />
        <div key={socialMediaModalKey}>
          <SocialMediaTasksModal
            isOpen={isSocialMediaTaskModalOpen}
            onDidDismiss={() => setIsSocialMediaTaskModalOpen(false)}
            task={selectedSocialMediaTask}
            icon={selectedSocialMediaTask ? getSocialMediaIcon(selectedSocialMediaTask) : IconNameEnum.QuestionMark}
            smallActionButton={true} />
        </div>
        <div key={taskModalKey}>
          <TaskModal isOpen={isTaskModalOpen} onDidDismiss={() => setIsTaskModalOpen(false)} task={selectedTask} icon={selectedTask ? tasksIcons[selectedTask.type] : IconNameEnum.QuestionMark} />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default EarnTab;
