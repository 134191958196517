import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import BuzzTab from './pages/BuzzTab';
import ApiaryTab from './pages/ApiaryTab';
import FriendsTab from './pages/FriendsTab';
import { useAppDispatch, useAppSelector, useInitMiniApp } from './hooks';
import { addCoinsFromOrder, fetchUser, selectApiaries, selectIdleCoinsPerHour, selectUserId, selectUserStatus } from './redux/userSlice';
import EnvironmentSelector from './pages/EnvironmentSelector';
import CharacterSelector from './pages/CharacterSelector';
import Login from './pages/Login';
import env from './environments';


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

import '@ionic/react/css/palettes/dark.always.css';
/* import '@ionic/react/css/palettes/dark.class.css'; */
// import '@ionic/react/css/palettes/dark.system.css';

/* Theme variables */
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'animate.css';
import './theme/variables.scss';
import './theme/tailwind.css';



import BoostPage from './pages/BoostPage';
import HBIcon from './components/HBIcon';
import { IconNameEnum } from './components/HBIcon/HBIcon';
import LevelsPage from './pages/LevelsPage';
import { fetchStock, getStockStatus } from './redux/stockSlice';
import HBSpinner from './components/HBSpinner';
import EarnTab from './pages/EarnTab';
import SettingsPage, { COINS_ANIMATION_KEY } from './pages/SettingsPage';
import UnsupportedDevicePage from './pages/UnsupportedDevicePage';
import RewardsTab from './pages/RewardsTab';
import LastActiveTracker from './components/LastActiveTracker/LastActiveTracker';
import { AnalyticsProvider } from './contexts/AnalyticsContext';
import ProgressModal from './components/ProgressModal';
import ErrorPage from './pages/ErorrPage';
import { StorageProvider, useStorage } from './contexts/StorageContext';
import CoinsAnimation from './components/CoinsAnimation';
import { CoinsAnimationProvider, useCoinsAnimation } from './contexts/CoinsAnimationContext';

const noAuthRoutes = ['start'];

const checkIfRouteMatch = (routes: string[]) => {
  const currentPath = window.location.pathname;
  return routes.some((route: string) => currentPath.startsWith(`/${route}`));
}

setupIonicReact();

const AnimatedCoinsWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const { isAnimationActive, resetAnimation } = useCoinsAnimation();

  return (
    <>
      {children}
      <CoinsAnimation
        isActive={isAnimationActive}
        onAnimationComplete={resetAnimation}
      />
    </>
  );
};

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector(selectUserId);
  const apiaries = useAppSelector(selectApiaries);
  const userStatus = useAppSelector(selectUserStatus);
  const stockStatus = useAppSelector(getStockStatus);
  const idleCoinsPerHour = useAppSelector(selectIdleCoinsPerHour);
  const token = localStorage.getItem('token');

  const isAuthRoute = !checkIfRouteMatch(noAuthRoutes);

  const [showProgressModal, setShowProgressModal] = useState(false);
  const [idleEarningFromLastLogin, setIdleEarningFromLastLogin] = useState(0);

  const handleModalDismiss = () => {
    setTimeout(() => {
      setShowProgressModal(false);
      dispatch(addCoinsFromOrder({ amount: idleEarningFromLastLogin }));
    }, 500); // Let modal close before adding coins
  };

  // Init or mock telegram mini app
  useInitMiniApp()

  useEffect(() => {
    if (env.name !== 'production') {
      import('eruda').then(eruda => eruda.default.init());
    } else {
      // Initialize Telegram Analytics SDK
      if ((window as any).telegramAnalytics) {
        (window as any).telegramAnalytics.init({
          token: env.tgToken,
          appName: env.tgName,
        });
      }
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (isAuthRoute && token) {
        if (userStatus === 'idle') {
          const res = await dispatch(fetchUser(true));
          if (res.payload && res.payload.idleEarningFromLastLogin) {
            if (res.payload.idleEarningFromLastLogin > 10) {
              setIdleEarningFromLastLogin(res.payload.idleEarningFromLastLogin);
              setShowProgressModal(true);
            }
          }
        }
      }
    };
    fetchData();
  }, [token, userStatus, stockStatus, dispatch, fetchStock]);

  const accumulatedCoinsRef = useRef(0);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (userStatus === 'succeeded' && idleCoinsPerHour > 0) {
      intervalId = setInterval(() => {
        // Calculate coins earned in 3 seconds
        const coinsEarnedIn3Seconds = (idleCoinsPerHour / 3600) * 3;

        // Accumulate coins
        accumulatedCoinsRef.current += coinsEarnedIn3Seconds;

        // If we've accumulated at least one whole coin, dispatch the action
        if (accumulatedCoinsRef.current >= 1) {
          const wholeCoins = Math.floor(accumulatedCoinsRef.current);
          dispatch(addCoinsFromOrder({ amount: wholeCoins }));

          // Subtract the added coins from the accumulator
          accumulatedCoinsRef.current -= wholeCoins;
        }
      }, 3000); // Run every 3 seconds
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [userStatus, idleCoinsPerHour, dispatch]);

  let characterSelected = false;
  let environmentSelected = false;

  if (apiaries) {
    characterSelected = apiaries?.some((apiary: any) => apiary.character);
    environmentSelected = apiaries?.some((apiary: any) => apiary.environment);
  }

  return (
    <StorageProvider>
      <IonApp style={{ 'background': '#272a2f' }}>
        {userId !== null && <LastActiveTracker />}
        <CoinsAnimationProvider>
          <AnalyticsProvider>
            <AnimatedCoinsWrapper>
              <IonReactRouter>
                <IonTabs>
                  <IonRouterOutlet animated={false}>
                    <Route exact path="/start" >
                      <Login />
                    </Route>
                    <Route exact path="/character">
                      <CharacterSelector />
                    </Route>
                    <Route exact path="/environment">
                      <EnvironmentSelector />
                    </Route>
                    <Route exact path="/boost">
                      <BoostPage />
                    </Route>
                    <Route exact path="/levels">
                      <LevelsPage />
                    </Route>
                    <Route exact path="/buzz">
                      <BuzzTab />
                    </Route>
                    <Route exact path="/apiary">
                      <ApiaryTab />
                    </Route>
                    <Route exact path="/earn">
                      <EarnTab />
                    </Route>
                    <Route path="/friends">
                      <FriendsTab />
                    </Route>
                    <Route path="/rewards">
                      <RewardsTab />
                    </Route>
                    <Route path="/settings">
                      <SettingsPage />
                    </Route>
                    <Route path="/unsupported">
                      <UnsupportedDevicePage />
                    </Route>
                    <Route path="/error">
                      <ErrorPage />
                    </Route>
                    <Route exact path="/">
                      {token ? (
                        userStatus === 'succeeded' ? (
                          !environmentSelected ? (
                            <Redirect to="/environment" />
                          ) : !characterSelected ? (
                            <Redirect to="/character" />
                          ) : (
                            <Redirect to="/buzz" />
                          )
                        ) : (
                          <HBSpinner />
                        )
                      ) : (
                        <Redirect to="/start" />
                      )}
                    </Route>
                  </IonRouterOutlet>

                  <IonTabBar id='hb-ion-tab-bar' slot="bottom">
                    <IonTabButton tab="buzz" href="/buzz">
                      <div className='hb-active-tab flex flex-col items-center'>
                        <HBIcon name={IconNameEnum.Bee} size={32} />
                        <IonLabel>Buzz</IonLabel>
                      </div>
                    </IonTabButton>
                    <IonTabButton tab="apiary" href="/apiary">
                      <div className='hb-active-tab flex flex-col items-center'>
                        <HBIcon name={IconNameEnum.Apiary} size={32} />
                        <IonLabel>Apiary</IonLabel>
                      </div>
                    </IonTabButton>
                    <IonTabButton tab="earn" href="/earn">
                      <div className='hb-active-tab flex flex-col items-center'>
                        <HBIcon name={IconNameEnum.Coins} size={32} />
                        <IonLabel>Earn</IonLabel>
                      </div>
                    </IonTabButton>
                    <IonTabButton tab="friends" href="/friends">
                      <div className='hb-active-tab flex flex-col items-center'>
                        <HBIcon name={IconNameEnum.Support} size={32} />
                        <IonLabel>Friends</IonLabel>
                      </div>
                    </IonTabButton>
                    <IonTabButton tab="rewards" href="/rewards">
                      <div className='hb-active-tab flex flex-col items-center'>
                        <HBIcon name={IconNameEnum.Cup} size={32} />
                        <IonLabel>Rewards</IonLabel>
                      </div>
                    </IonTabButton>
                  </IonTabBar>
                </IonTabs>
              </IonReactRouter>

              <ProgressModal
                isOpen={showProgressModal}
                onDidDismiss={handleModalDismiss}
                earning={idleEarningFromLastLogin} />
            </AnimatedCoinsWrapper>
          </AnalyticsProvider>
        </CoinsAnimationProvider>
      </IonApp>
    </StorageProvider>
  );
};

export default App;
